const spots_arr = spots;
const spots_set = spots_arr.map((e) => e ? [e.lat, e.lng, e.address] : []);
const content = spots.map((e) => [e.address]);
const styles = [
  {
    "featureType": "administrative.locality",
    "elementType": "all",
    "stylers": [
      {
        "hue": "#2c2e33"
      },
      {
        "saturation": 7
      },
      {
        "lightness": 19
      },
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "administrative.locality",
    "elementType": "labels.text",
    "stylers": [
      {
        "color": "#356863"
      },
      {
        "weight": "0.01"
      }
    ]
  },
  {
    "featureType": "administrative.neighborhood",
    "elementType": "labels.text",
    "stylers": [
      {
        "color": "#d0982a"
      },
      {
        "weight": "0.01"
      }
    ]
  },
  {
    "featureType": "landscape",
    "elementType": "all",
    "stylers": [
      {
        "hue": "#ffffff"
      },
      {
        "saturation": -100
      },
      {
        "lightness": 100
      },
      {
        "visibility": "simplified"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "all",
    "stylers": [
      {
        "hue": "#ffffff"
      },
      {
        "saturation": -100
      },
      {
        "lightness": 100
      },
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "geometry",
    "stylers": [
      {
        "hue": "#bbc0c4"
      },
      {
        "saturation": -93
      },
      {
        "lightness": 31
      },
      {
        "visibility": "simplified"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "labels",
    "stylers": [
      {
        "hue": "#bbc0c4"
      },
      {
        "saturation": -93
      },
      {
        "lightness": 31
      },
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "labels",
    "stylers": [
      {
        "hue": "#bbc0c4"
      },
      {
        "saturation": -93
      },
      {
        "lightness": -2
      },
      {
        "visibility": "simplified"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "geometry",
    "stylers": [
      {
        "hue": "#e9ebed"
      },
      {
        "saturation": -90
      },
      {
        "lightness": -8
      },
      {
        "visibility": "simplified"
      }
    ]
  },
  {
    "featureType": "transit",
    "elementType": "all",
    "stylers": [
      {
        "hue": "#e9ebed"
      },
      {
        "saturation": 10
      },
      {
        "lightness": 69
      },
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "all",
    "stylers": [
      {
        "hue": "#e9ebed"
      },
      {
        "saturation": -78
      },
      {
        "lightness": 67
      },
      {
        "visibility": "simplified"
      }
    ]
  }]

  function initMap() {
    let bounds = new google.maps.LatLngBounds();
    const mapOptions = {
      mapTypeId: 'roadmap',
      disableDefaultUI: true,
      styles: styles
    };
  
    // Display a map on the web page
    let map = new google.maps.Map(document.getElementById("map"), mapOptions);
    if (map) {
      map.setTilt(50);
  
      // Multiple markers location, latitude, and longitude
      const markers = [...spots_set];
  
      // Info window content
      const infoWindowContent = [...content];
  
      // Add multiple markers to map
      let infoWindow = new google.maps.InfoWindow({ maxWidth: 350 }), marker, i;
  
  
  
      // Place each marker on the map  
      for (i = 0; i < markers.length; i++) {
        const position = new google.maps.LatLng(markers[i][0], markers[i][1]);
        bounds.extend(position);
        marker = new google.maps.Marker({
          position: position,
          map: map,
          icon: '/wp-content/themes/bluehippo/img/pin-map.webp',
        });
  
        // Add info window to marker    
        google.maps.event.addListener(marker, 'click', (function (marker, i) {
          return function () {
            infoWindow.setContent(infoWindowContent[i][0]);
            infoWindow.open(map, marker);
          }
        })(marker, i));
  
        // Center the map to fit all markers on the screen
        map.fitBounds(bounds);
      }
  
      // Set zoom level
      const boundsListener = google.maps.event.addListener((map), 'bounds_changed', function (event) {
        this.setZoom(11);
        google.maps.event.removeListener(boundsListener);
      });
    }
  }
  
  window.initMap = initMap;